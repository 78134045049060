<template>
  <el-dialog class="showImgDialog" :center="true" title="编辑紧急联系人信息" :visible.sync="editPerpeoShow"
    :append-to-body="true" @open="getUserInfo" @close="close('ruleForm')">
    <el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm" label-width="110px">
      <el-row type="flex" justify="space-between">
        <el-col :span="11">
          <el-form-item label="紧急联系人姓名" prop="sosUserName">
            <el-input v-model="ruleForm.sosUserName" placeholder="请输入紧急联系人姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item label="紧急联系人电话" prop="sosUserPhone"
            :rules="[{ required: false, message: '请输入接收通知电话', trigger: 'blur' },
            { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号' }]">
            <el-input v-model="ruleForm.sosUserPhone" placeholder="请输入紧急联系人电话"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="11">
          <el-form-item label="紧急联系人关系" prop="sosRelation">
            <el-input v-model="ruleForm.sosRelation" placeholder="请输入紧急联系人关系"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog()">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>

import { userEdit, userDescribe } from '../../api';
export default {

  data () {
    return {
      ruleForm: {
        sosUserName: '',
        sosUserPhone: '',
        sosRelation: '',
      },

      rules: {},

    };
  },
  created () {

  },
  props: {
    editPerpeoShow: Boolean,
    code: String
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await userEdit({
            code: this.code,
            ...this.ruleForm
          });
          this.resetForm(formName);
          this.close(formName)
        } else {
          return false;
        }
      });
    },
    async getUserInfo () {
      const result = await userDescribe({ code: this.code });

      Object.keys(this.ruleForm).forEach(key => {
        this.ruleForm[key] = result[key];
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    close (formName) {
      this.resetForm(formName);
      this.closeDialog()
    },
    closeDialog () {
      this.$emit('update:editPerpeoShow', false);
      this.$emit('close', this.editPerpeoShow);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}

.el-dialog__body {
  max-height: 500px;
  overflow: auto;
  padding: 10px 40px;
}
</style>
